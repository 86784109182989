.login-page-container {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25% !important;
  .login-form {
    width: 100% !important;
  }
  .login-page-icon-container {
    display: flex;
    justify-content: center;
    padding: 1em;
    .login-page-icon {
      width: 60%;
      user-select: none;
    }
  }
}
