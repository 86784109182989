.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #328746;
}

.ant-layout-sider {
  background-color: #000000 !important;
}

.ant-menu {
  background-color: #000000 !important;
}

.fireshield-sider {
  min-width: 220px !important;
  max-width: 220px !important;
}

.logo-container {
  height: 12vh;
  //background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  .logo-icon {
    height: 100%;
    user-select: none;
  }
}
.logo-container-collapsed {
  //width: 5vw !important;
  height: 5vh !important;
}

.site-layout .site-layout-background {
  background: #fff;
}

.link-color {
  color: #fff !important;
}
